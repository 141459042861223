<template>
  <!-- Main  -->
      <section class="hidden lg:block hero-bg h-screen" id="main">
          <div class="flex flex-col justify-center items-center pt-44 md:pt-96 lg:pt-60 2xl:pt-72 font-oxanium">
              <h1 v-motion-pop-visible class="text-white text-center font-extrabold text-5xl md:text-[90px] lg:text-[72px] uppercase">Step into
                <br class="block md:hidden lg:block"> <span class="text-[#FFCE00]">Austinski</span>’s <br> World!</h1>
              <div class="flex flex-col md:flex-row gap-4 mt-10 px-8">
                <a href="https://roobet.com/?ref=austinski" target="_blank">
                  <button v-motion-slide-visible-right class="text-white font-extrabold bg-transparent hover:bg-[#FFCE00] border border-white hover:border-[#FFCE00] hover:text-black font-base py-4 px-[40px] relative uppercase rounded-xl hover:transition hover:ease-in-out scale-105 hover:duration-200">
                    REGISTER ON ROOBET.COM NOW!
                  </button>
                </a>
                <a href="https://kick.com/austinski" target="_blank">
                  <button v-motion-slide-visible-left class="text-white font-extrabold bg-transparent hover:bg-[#FFCE00] border border-white hover:border-[#FFCE00] hover:text-black font-base py-4 px-14 relative uppercase rounded-xl">
                    WATCH LIVE STREAM ON KICK
                  </button>
                </a>
              </div>
          </div>
      </section>
      <section class="block lg:hidden hero-bg-mobile h-screen" id="main">
          <div class="flex flex-col justify-center items-center pt-44 md:pt-96 lg:pt-60 2xl:pt-72 font-oxanium">
              <h1 v-motion-pop-visible class="text-white text-center font-extrabold text-5xl md:text-[90px] lg:text-[72px] uppercase">Step into
                <br class="block md:hidden lg:block"> <span class="text-[#FFCE00]">Austinski</span>’s <br> World!</h1>
              <div class="flex flex-col md:flex-row gap-4 mt-10 px-8">
                <a href="https://roobet.com/?ref=austinski" target="_blank">
                  <button v-motion-pop-visible class="text-white font-extrabold bg-transparent hover:bg-[#FFCE00] border border-white hover:border-[#FFCE00] hover:text-black font-base py-4 px-[43px] relative uppercase rounded-xl hover:transition hover:ease-in-out scale-105 hover:duration-200">
                    REGISTER ON ROOBET.COM NOW!
                  </button>
                </a>
                <a href="https://kick.com/austinski" target="_blank">
                  <button v-motion-pop-visible class="text-white font-extrabold bg-transparent hover:bg-[#FFCE00] border border-white hover:border-[#FFCE00] hover:text-black font-base py-4 px-14 relative uppercase rounded-xl">
                    WATCH LIVE STREAM ON KICK
                  </button>
                </a>
              </div>
          </div>
      </section>
      <!-- Raffle Section -->
      <section class="h-auto px-6 md:px-6 lg:px-36 2xl:px-[340px] md:pt-20 lg:py-20">
        <div class="flex flex-col justify-center items-center font-oxanium">
          <p v-motion-pop-visible class="text-white font-bold text-4xl uppercase">Promotions</p>
        </div>
        <div class="flex justify-center items-center">
          <div v-motion-pop-visible class="hidden md:block h-[700px] w-[1200px] md:h-[500px] promotion-frame-bg">
              <div class="flex flex-row justify-center md:justify-start items-center">
                  <div class="flex flex-col items-start md:ml-14 lg:ml-20 gap-2 lg:w-[50%] mt-14 md:mt-10 lg:mt-24">
                      <div class="flex flex-col gap-3 font-montserrat">
                          <span class="text-xl md:text-xl lg:text-3xl font-black text-white text-center md:text-left uppercase">Use Code</span>
                          <span class="text-4xl md:text-4xl lg:text-7xl font-black text-[#FFCE00] text-center md:text-left uppercase">AUSTINSKI</span>
                          <span class="text-xl md:text-xl lg:text-3xl font-black text-white text-center md:text-left uppercase">ON ROOBET.COM</span>
                          <a href="https://roobet.com/?ref=austinski" target="_blank">
                            <button class="font-extrabold text-xs md:text-base bg-transparent  bg-[#FFCE00] border border-[#FFCE00] text-black py-3 px-3 md:py-2 lg:py-4 p-5 lg:px-[40px] relative uppercase rounded-xl transition ease-in-out hover:scale-105 duration-200">
                              REGISTER ON ROOBET.COM NOW!
                            </button>
                          </a>
                      </div>
                  </div>
              </div>
          </div>
          <div v-motion-pop-visible class="md:hidden h-[700px] w-[1200px] md:h-[500px] promotion-frame-mobile-bg container mx-auto">
              <div class="flex flex-row justify-center md:justify-start items-center">
                  <div class="flex flex-col items-start md:ml-14 lg:ml-20 gap-2 lg:w-[50%] mt-14 md:mt-10 lg:mt-24">
                      <div class="flex flex-col gap-3 font-montserrat">
                          <span class="text-xl md:text-xl lg:text-3xl font-black text-white text-center md:text-left uppercase">Use Code</span>
                          <span class="text-4xl md:text-4xl lg:text-7xl font-black text-[#FFCE00] text-center md:text-left uppercase">AUSTINSKI</span>
                          <span class="text-xl md:text-xl lg:text-3xl font-black text-white text-center md:text-left uppercase">ON ROOBET.COM</span>
                          <a href="https://roobet.com/?ref=austinski" target="_blank">
                            <button class="font-extrabold text-xs md:text-base bg-transparent  bg-[#FFCE00] border border-[#FFCE00] text-black py-3 px-3 md:py-2 lg:py-4 p-5 lg:px-[40px] relative uppercase rounded-xl transition ease-in-out hover:scale-105 duration-200">
                              REGISTER ON ROOBET.COM NOW!
                            </button>
                          </a>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </section>

      <section class="h-auto px-6 md:px-6 lg:px-36 2xl:px-[340px] py-20 lg:py-20 leaderboard-bg" id="leaderboards">
        <div class="flex flex-col justify-center items-center font-oxanium">
          <p v-motion-pop-visible class="text-white font-bold text-4xl uppercase">Leaderboard</p>
        </div>
        <div>
           <RoobetLeaderboardSection/>
        </div>
      </section>

      <!-- Socials Section -->
      <section class="h-auto px-6 md:px-6 lg:px-36 2xl:px-[340px] pt-24 pb-40" id="socials">
          <div class="flex flex-col justify-center items-center" style="font-family: Oxanium, sans-serif;">
            <p v-motion-pop-visible class="text-white font-bold text-4xl uppercase">Socials</p>
          </div>
          <div class="flex flex-wrap justify-center items-center gap-8 md:gap-12 lg:gap-14 mt-20" style="font-family: Oxanium, sans-serif;">
             <div v-motion-pop-visible v-for="(image, index) in socialsImages" :key="index" class="flex flex-col justify-center items-center gap-3">
                <a :href="image.link" target="_blank">
                  <img 
                    :src="image.current"
                    @mouseover="swapImage(index, image.hover)"
                    @mouseleave="swapImage(index, image.default)"
                    alt="kick" 
                    class="w-28 md:w-28 lg:w-32"
                    />
                </a>
             </div>
          </div>
          <div class="mt-10">
              <img src="../assets/socials-bg-path.png">
          </div>
      </section>
</template>

<script>
import RoobetLeaderboardSection from "@/sections/RoobetLeaderboardSection.vue";
import { defineComponent } from "vue";


export default defineComponent({
  components: {
     RoobetLeaderboardSection
  },
  data() {
    return {
      loading: false,
      socialsImages: [
        {
          default: require('@/assets/youtube-socials.png'),
          hover: require('@/assets/youtube-socials-hover.png'),  
          current: require('@/assets/youtube-socials.png'),
          link: 'https://www.youtube.com/@AustinskiKICK',
          name: 'Youtube' 
        },
        {
          default: require('@/assets/insta-socials.png'),
          hover: require('@/assets/insta-socials-hover.png'),  
          current: require('@/assets/insta-socials.png'),
          link: 'https://www.instagram.com/austin_ski850',
          name: 'Instagram' 
        },
        {
          default: require('@/assets/twitter-socials.png'),
          hover: require('@/assets/twitter-socials-hover.png'),  
          current: require('@/assets/twitter-socials.png'),
          link: 'https://x.com/austinski850',
          name: 'Twitter / X' 
        },
        {
          default: require('@/assets/discord-socials.png'),
          hover: require('@/assets/discord-socials-hover.png'),  
          current: require('@/assets/discord-socials.png'),
          link: 'https://discord.com/invite/8gsrqdrf5r',
          name: 'Discord' 
        },
        {
          default: require('@/assets/kick-socials.png'),
          hover: require('@/assets/kick-socials-hover.png'),  
          current: require('@/assets/kick-socials.png'),
          link: 'https://kick.com/toppull',
          name: 'Kick'
        },
        {
          default: require('@/assets/tiktok-socials.png'),
          hover: require('@/assets/tiktok-socials-hover.png'),  
          current: require('@/assets/tiktok-socials.png'),
          link: 'https://www.tiktok.com/@austinski850',
          name: 'Tiktok' 
        },
      ],
    };
  },
  methods: {
    swapImage(index, newImage) {
      this.socialsImages[index].current = newImage;
    },
    isMobile() {
      return window.innerWidth < 1024;
    },
  }
});
</script>

<style scope>


.main-btn:hover .main-span {
  border-color: #1294F0;
}

.leaderboard-bg {
  background-image: url("../assets/leaderboard-bg-path.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.promotion-frame-bg {
  background-image: url('../assets/promotion-frame-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.promotion-frame-mobile-bg {
  background-image: url('../assets/promotion-frame-mobile-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
