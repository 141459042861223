<template>
  <!-- Footer start -->
  <section class="bg-[#090B1D] h-auto w-full flex">
      <div class="w-full">
        <div class="flex flex-col lg:flex-row lg:justify-between items-start lg:items-center container mx-auto px-6 py-6 lg:py-14 2xl:px-40 gap-6 lg:gap-0">
           <img src="../assets/austinski-nav-logo.png" class="w-[180px]">
           <div class="flex flex-col lg:flex-row gap-4 lg:gap-10 text-white text-sm font-extrabold tracking-widest font-lato uppercase">
              <a href="/" class="hover:text-[#FFCE00]">Home</a>
              <a href="/#leaderboards" class="hover:text-[#FFCE00]">Leaderboard</a>
              <a href="/#socials" class="hover:text-[#FFCE00]">Socials</a>
              <router-link to="/challenges" class="hover:text-[#FFCE00]">Challenges</router-link>
           </div>
           <div class="flex flex-col justify-center items-center gap-1">
              <div class="flex justify-center items-center space-x-2">
                <a href="https://www.youtube.com/@AustinskiKICK" target="_blank">
                  <img src="../assets/youtube.png" alt="youtube" class="w-7 h-7">
                </a>
                <a href="https://www.instagram.com/austin_ski850/" target="_blank">
                  <img src="../assets/instagram.png" alt="instagram" class="w-7 h-7">
                </a>
                <a href="https://x.com/austinski850" target="_blank">
                  <img src="../assets/twitter.png" alt="twitter" class="w-6 h-6">
                </a>
                <a href="https://discord.com/invite/8gsrqdrf5r" target="_blank">
                  <img src="../assets/discord.png" alt="discord" class="w-6 h-6">
                </a>
                <a href="https://kick.com/toppull" target="_blank">
                  <img src="../assets/kick.png" alt="kick" class="w-6 h-6">
                </a>
                <a href="https://www.tiktok.com/@austinski850" target="_blank">
                  <img src="../assets/tiktok.png" alt="tiktok" class="w-6 h-6">
                </a>
              </div>
           </div>
        </div>
        <div class="flex flex-col justify-center items-center text-center container mx-auto pb-14 gap-10 px-6 py-6 2xl:px-40 font-lato">
           <div class="text-[#BBBBBB] text-sm md:text-sm font-bold tracking-widest">
            If you ever feel that gambling is becoming a problem,
            we urge you to seek help and support. 
            <br>
            Most people gamble for fun and enjoyment. However, some people think of gambling as a way to make money, spend more than they can afford, or use gambling to distract themselves from everyday problems.
            <br>
            To find further advice, help and support visit: https://www.begambleaware.org/
           </div>
           <div class="text-[#BBBBBB] text-sm md:text-sm font-bold tracking-widest">
            We do not take responsibility for misunderstood promotions, losses from gambling in casinos or betting on sites which are linked to or promoted on this website. 
            Make sure it is legal for you to use each site inside your jurisdiction, and ALWAYS gamble responsibly!
           </div>
        </div>
      </div>
  </section>
  <!-- Footer end -->
</template>

<script>
export default {
    methods: {
      isMobile() {
        return window.innerWidth < 1024;
      },
    }
};
</script>

<style scope>

</style>
